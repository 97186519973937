<template>
  <!-- 围栏策略 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="策略：">
        <el-input maxlength="15" placeholder="请输入围栏策略" @keyup.enter.native="onSubmit" v-model="queryForm.strategy"
          @input="(e) => (queryForm.strategy = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="围栏策略">
      <template slot="heard-right">
        <div>
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('5d478b129e98444c8a2824a5')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="handelGenerateFence('addnew')"
            v-if='this.getUserInfo.user_name != "admin" && isShowBtn("4b2ab218933c4f9d9fe02c3b")'>新增</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="zone_of_action">
            <span>{{ zoneOfAction(row.zone_of_action) }}</span>
          </template>
          <template slot-scope="{row}" slot="static_rule">
            <span>{{ staticRuleName(row.static_rule) }}</span>
          </template>
          <template slot-scope="{row}" slot="inspector_personnel">
            <el-link type="primary" target="_blank" v-if="row.inspector_personnel.length > 0"
              @click="openRailPersonnel(row)">{{ row.inspector_personnel.length }}</el-link>
            <span v-else>{{ row.inspector_personnel.length }}</span>
          </template>
          <template slot-scope="{row}" slot="create_time">
            <span>{{ validDateTime(row.create_time) }}</span>
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="warning" icon="bianji" hint="编辑" @click="handelGenerateFence(row._id)"
              v-if="isShowBtn('b8abbf7e329448f1b85a45c6')"></lbButton>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
          <el-table-column prop="strategy" label="策略">
          </el-table-column>
          <el-table-column label="活动区域">
            <template slot-scope="scope">
              <span>{{ zoneOfAction(scope.row.zone_of_action) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="静止规则">
            <template slot-scope="scope">
              <span>{{ staticRuleName(scope.row.static_rule) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="人数">
            <template slot-scope="scope">
              <el-link type="primary" target="_blank" v-if="scope.row.inspector_personnel.length > 0"
                @click="openRailPersonnel(scope.row)">{{ scope.row.inspector_personnel.length }}</el-link>
              <span v-else>{{ scope.row.inspector_personnel.length }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              <span>{{ validDateTime(scope.row.create_time) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="handelGenerateFence(scope.row._id)"
                v-if="isShowBtn('b8abbf7e329448f1b85a45c6')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="TotalCount"></el-pagination>
      </div>
    </le-card>
    <!--人员详情对话框 -->
    <lebo-dialog title="人员列表" :isShow="isAttendancePersonnel" @close="isAttendancePersonnel = false" width="30%"
      footerSlot closeOnClickModal>
      <el-table :data="attendancePersonnelData" tooltip-effect="dark" style="width: 100%" border stripe height="460">
        <el-table-column type="index" width="150" align="center" header-align="center" label="序号"></el-table-column>
        <el-table-column prop="user_name" label="姓名"></el-table-column>
        <el-table-column prop="person_type" label="人员类型">
          <template slot-scope="scope">
            <span>{{ getWayName("RegisterPersonType", scope.row.person_type) }} </span>
          </template>
        </el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 新增编辑围栏策略 -->
    <lebo-dialog :title="dialogGenerateFenceId === 'addnew' ? '新增围栏策略' : '编辑围栏策略'" width="60%" :isShow="showAddGenerateFence"
      @close="showAddGenerateFence = false" footerSlot>
      <addGenerateFence v-if="showAddGenerateFence" :id="dialogGenerateFenceId" @closeDialog="closeGenerateFence">
      </addGenerateFence>
    </lebo-dialog>
  </div>
</template>
<script>
import { getRingFenceList, delRingFence, getFenceInspectorList } from '@/api/administrative'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addGenerateFence from './components/addGenerateFence/index.vue'
export default {
  components: { addGenerateFence },
  data () {
    return {
      // 查询表单
      queryForm: {
        strategy: null,
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      seleteItem: [],
      TotalCount: 0,
      isAttendancePersonnel: false,
      attendancePersonnelData: [],
      showAddGenerateFence: false,
      dialogGenerateFenceId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '策略',
            prop: 'strategy'
          }, {
            label: '活动区域',
            prop: 'zone_of_action',
            slot: true
          }, {
            label: '静止规则',
            prop: 'static_rule',
            slot: true
          }, {
            label: '人数',
            prop: 'inspector_personnel',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
      console.log(this.queryForm)
    }
    this.requestInfoList()
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getUserInfo', 'isAdmin', 'getOptions', 'getWayName'])
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    // this.requestPorkingList();
    // this.requestFindList();
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 批量删除
    batchDel () {
      if (this.seleteItem.length > 0) {
        this.$confirm('此操作将永久删除选中的围栏策略, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.seleteItem.length; i++) {
            arr.push(this.seleteItem[i]._id)
            if (arr.length === this.seleteItem.length) {
              obj._ids = arr
              this.fnDelRingFence(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的围栏策略！')
      }
    },
    async fnDelRingFence (obj) {
      const res = await delRingFence(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.onSubmit()
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestInfoList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestInfoList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestInfoList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestInfoList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestInfoList()
    // },
    // 编辑
    editit (row) {
      this.$router.push({
        path: `/add-generate-fence/${row._id}`
      })
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.seleteItem = list
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      this.seleteItem = val
    },
    async requestInfoList () {
      try {
        const res = await getRingFenceList(this.queryForm)
        console.log('res = ', JSON.stringify(res))
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
        this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      } catch (error) { }
    },
    staticRuleName (id) {
      switch (id) {
        case 1:
          return '静止超过10分钟告警'
        case 2:
          return '静止超过30分钟告警'
        case 3:
          return '静止超过60分钟告警'
        default:
          return '/'
      }
    },
    zoneOfAction (id) {
      switch (id) {
        case 1:
          return '区域越界超过300米'
        case 2:
          return '区域越界超过500米'
        case 3:
          return '区域越界超过100米'
        default:
          return '/'
      }
    },
    async openRailPersonnel (item) {
      const res = await getFenceInspectorList({
        ring_fence_id: item._id
      })
      console.log(res)
      if (res && res.Code === 200) {
        this.attendancePersonnelData = res.Data
        this.isAttendancePersonnel = true
      } else {
        this.attendancePersonnelData = []
      }
    },
    closeGenerateFence () {
      this.showAddGenerateFence = false
      this.requestInfoList()
    },
    // 新增编辑围栏策略
    handelGenerateFence (id) {
      this.dialogGenerateFenceId = id
      this.showAddGenerateFence = true
    }
  }
}
</script>

<style lang="less" scoped></style>
