<template>
  <div class="box-card" style="text-align: left">
    <!-- 主要内容 -->
    <el-form :model="queryForm" :rules="rule" ref="queryForm" class="addform_box" label-width="120px">
      <el-form-item label="策略名称：" prop="strategy">
        <el-input maxlength="18" v-model="queryForm.strategy"
          @input="(e) => (queryForm.strategy = validSpacingSpecial(e))" placeholder="请输入策略名称"></el-input>
      </el-form-item>
      <!-- <el-form-item label="巡检人员：" prop="inspector_personnel" label-width="120px">
          <el-select popper-class="my-select" style="width:400px" v-model="queryForm.inspector_personnel" multiple collapse-tags placeholder="请选择巡检人员" filterable>
            <el-option v-for="item in inspector_personnel_list" :key="item._id" :label="item.user_name" :value="item._id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="组织：" prop="organize_id">
            <el-select popper-class="my-select" filterable v-model="queryForm.organize_id" placeholder="请选择组织" clearable
              @change="selectOrganize()">
              <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name"
                :value="item._id"></el-option>
            </el-select>
          </el-form-item>
      <el-form-item label="适用人员：" prop="strategy_personnel_count">
        <el-select popper-class="my-select" ref="organizeSelect" v-model="queryForm.strategy_personnel_count"
          placeholder="请选择适用人员" :popper-append-to-body="false">
          <el-option disabled clearable :value="queryForm.strategy_personnel_count" class="setstyle">
            <el-tree @check="handleNodeClick" :data="strategyPersonList" show-checkbox :props="defaultProps"
              ref="strategyPersonTree" node-key="id" :highlight-current="true"
              :default-checked-keys="queryForm.strategyJgData" default-expand-all>
            </el-tree>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item required label="管控时段：" style="margin-bottom:0px!important;">
        <div v-for="(item, index) in queryForm.control_period" :key="index" style="display: flex;margin-bottom:22px;">
          巡检时段
          <el-form-item :prop="'control_period[' + index + '].begin_time'"
            :rules="{ required: true, message: '请选择上班时间', trigger: 'blur' }" style="margin-left:10px;margin-right:10px;">
            <el-time-select style="width:160px" v-model="item.begin_time" :editable="false"
              :picker-options="{ start: '00:00', step: '00:15', end: '23:45', minTime: index === 0 ? '' : queryForm.control_period[index - 1].end_time }"
              placeholder="请选择上班时间" align="center"></el-time-select>
          </el-form-item>
          ~
          <el-form-item :prop="'control_period[' + index + '].end_time'"
            :rules="{ required: true, message: '请选择下班时间', trigger: 'blur' }" style="margin-left:10px;margin-right:10px;">
            <el-time-select style="width:160px" v-model="item.end_time" :editable="false"
              :picker-options="{ start: '00:15', step: '00:15', end: '24:00', minTime: queryForm.control_period[index].begin_time }"
              placeholder="请选择下班时间" align="center"></el-time-select>
          </el-form-item>
          <el-form-item>
            <el-button style="padding:8px!important;" icon="el-icon-plus" size="mini" circle
              @click="addTimePeriod(index)"></el-button>
          </el-form-item>
          <el-form-item>
            <el-button style="margin-left:10px;padding:8px!important;" icon="el-icon-minus" size="mini" circle
              v-if="index > 0" @click="reduceTimePeriod(index)"></el-button>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="静止规则：" prop="static_rule">
        <el-select popper-class="my-select" v-model="queryForm.static_rule" placeholder="请选择静止规则">
          <el-option v-for="item in static_rule_list" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动区域：" prop="zone_of_action">
        <el-select popper-class="my-select" v-model="queryForm.zone_of_action" placeholder="请选择活动区域">
          <el-option v-for="item in zone_of_action_list" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <div class="map-container" id="map">
          <!-- <div class="float-select">
                        <input class="inputmap" id="inputkeyword" placeholder="请输入地名" autocomplete="off" />
                    </div> -->
          <div class="searchMap">
            <el-form :inline="true" size="small">
              <el-form-item width="100">
                <el-select popper-class="my-select" v-model="addressInfo.province_code" filterable clearable
                  placeholder="请选择省" @change="provinceChange">
                  <el-option v-for="item in provinceSelectList" :key="item.specific_code" :label="item.specific_name"
                    :value="item.specific_code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select popper-class="my-select" v-model="addressInfo.city_code" filterable clearable
                  placeholder="请选择市" @change="cityChange">
                  <el-option v-for="item in citySelectList" :key="item.specific_code" :label="item.specific_name"
                    :value="item.specific_code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select popper-class="my-select" v-model="addressInfo.district_code" filterable clearable
                  placeholder="请选择区" @change="districtChange">
                  <el-option v-for="item in districtSelectList" :key="item.specific_code" :label="item.specific_name"
                    :value="item.specific_code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input id="inputkeyword" v-model="keyword" placeholder="请输入关键字"
                  @input="(e) => (keyword = validSpace(e))"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="float-button">
            <el-button type="warning" size="small" plain v-if="queryForm.regional_location.length > 0"  @click="startEditorPoly()">修 改</el-button>
              <el-button type="danger" size="small" plain v-if="queryForm.regional_location.length > 0" @click="resetDrawe()">重 置</el-button>
              <el-button type="primary" size="small" plain v-if="adjustment" @click="saveEditorPoly()">保 存</el-button>
            <!-- <div v-if="queryForm.regional_location.length > 0" class="btn" @click="resetDrawe()">
              <span>重置</span>
            </div>
            <div v-if="queryForm.regional_location.length > 0" class="btn" @click="startEditorPoly()"
              style="margin-bottom: 5px">
              <span>修改</span>
            </div>
            <div v-if="adjustment" class="btn" @click="saveEditorPoly()">
              <span>保存</span>
            </div> -->
          </div>
          <div class="float-span">
              <span v-if="!queryForm.regional_location.length > 0" class="tip">点击鼠标右键确定</span>
            </div>
        </div>

    </el-form>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="saveit" v-preventReClick>保 存</lbButton>
  </div>
</template>

<script>
import { addRingFence, updateRingFence, getAttendanceGroupInspectorList, getRingFenceInspectorList, getFenceDetail, getProvinceList, getFenceWithoutConfiguredRules, getOrganizList } from '@/api/administrative'
import { getOrganizationSeleteList } from '@/api/securityApi'
import addressjs from '@/utils/city'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      title: '',
      queryForm: {
        strategy: '', // 策略名称
        zone_of_action: '', // 活动区域
        static_rule: '', // 静止规则
        control_period: [
          {
            begin_time: '',
            end_time: ''
          }
        ], // 管控时段
        strategy_personnel_count: '',
        personnel_count: 0,
        regional_location: [], // 区域位置
        inspector_personnel: [], // 架构人员+巡检人员id
        strategyJgData: [],
        // checkedIds: [], // 回显选择好的id
        position: '',
        longitude: '',
        latitude: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: '',
        organize_id: '', // 组织id
        selected_organize_ids: [] // 最下层架构id
      },
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      rule: {
        strategy: [
          { required: true, message: '请输入策略名称', trigger: 'blur' }
        ],
        inspector_personnel: [
          { required: true, message: '请选择巡检人员', trigger: 'change' }
        ],
        zone_of_action: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        static_rule: [
          { required: true, message: '请选择静止规则', trigger: 'change' }
        ],
        strategy_personnel_count: [
          { required: true, message: '请选择适用人员', trigger: 'change' }
        ],
        organize_id: [
          { required: true, message: '请选组织', trigger: 'change' }
        ]
      },
      static_rule_list: [{
        value: 1,
        label: '静止超过10分钟告警'
      }, {
        value: 2,
        label: '静止超过30分钟告警'
      }, {
        value: 3,
        label: '静止超过60分钟告警'
      }],
      zone_of_action_list: [{
        value: 1,
        label: '区域越界超过300米'
      }, {
        value: 2,
        label: '区域越界超过500米'
      }, {
        value: 3,
        label: '区域越界超过1000米'
      }],
      inspector_personnel_list: [],
      polyEditor: null,
      polygon: null,
      mouseTool: null,
      map: null,
      adjustment: false,
      ranges: [8, 16],
      parking_list: [],
      parking_select_name: [],
      strategyPersonList: [],
      // 围栏策略人员数据列表id
      strategyPersonnelDataId: [],
      // 组织下拉列表
      organizationSeleteList: [],
      person_count: 0,
      marker: null,
      addressInfo: {
        longitude: '',
        latitude: '',
        address: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      },
      provinceSelectList: [], // 省份下拉列
      citySelectList: [], // 市下拉列
      districtSelectList: [], // 区下拉列
      autocomplete: null,
      keyword: '',
      rePaint: false // 是否是重绘
    }
  },
  created () {
    this.title = this.id === 'addnew' ? '新增策略' : '编辑策略'
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    defaultForm () {
      this.setQueryStatus(2)
      this.$emit('closeDialog', false)
    },
    saveit () {
      this.saveEditorPoly()
      this.submitForm('queryForm')
    },
    resetForm (refsName) {
      this.$refs[refsName].resetForm()
    },
    submitForm (formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.queryForm.regional_location.length > 0) {
            this.queryForm.longitude = this.addressInfo.longitude
            this.queryForm.latitude = this.addressInfo.latitude
            this.queryForm.addres = this.addressInfo.address
            this.queryForm.province = this.addressInfo.province
            this.queryForm.province_code = this.addressInfo.province_code
            this.queryForm.city = this.addressInfo.city
            this.queryForm.city_code = this.addressInfo.city_code
            this.queryForm.district = this.addressInfo.district
            this.queryForm.district_code = this.addressInfo.district_code
            console.log(' 提交前this.queryForm', this.queryForm)
            that.id === 'addnew' ? that.addStrategy() : that.editStrategy()
          } else {
            that.$message({
              message: '请绘制电子围栏',
              type: 'warning'
            })
          }
        } else {
          return false
        }
      })
    },
    initMap () {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        // 1.创建地图实例
        var map = new AMap.Map('map', {
          //   center: [116.400274, 39.905812],
          zoom: 18,
          features: ['bg', 'road', 'building'] // , "point" 地标太多了显得乱
        })
        that.map = map
        this.mouseTool = new AMap.MouseTool(map)
        map.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
          var autoOptions = {
            input: 'inputkeyword'
          }
          var auto = new AMap.Autocomplete(autoOptions)
          var placeSearch = new AMap.PlaceSearch({
            map: map
          }) // 构造地点查询类
          AMap.event.addListener(auto, 'select', select) // 注册监听，当选中某条记录时会触发
          function select (e) {
            placeSearch.setCity(e.poi.adcode)
            placeSearch.search(e.poi.name) // 关键字查询查询
            console.log('=-=-=-e=', JSON.stringify(e))
          }
        })
        // 定位
        AMap.plugin('AMap.Geolocation', function () {
          var geolocation = new AMap.Geolocation({
            enableHighAccuracy: false, // 是否使用高精度定位，默认:true
            timeout: 10000, // 超过10秒后停止定位，默认：5s
            buttonPosition: 'RB', // 定位按钮的停靠位置
            buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: false, // 定位成功后是否自动调整地图视野到定位点
            showCircle: false // 是否显示定位精度圈
          })
          that.map.addControl(geolocation)
          // geolocation.getCityInfo(function (status, result) {
          //   console.log('定位中')

          //   if (status === 'complete') {
          //     console.log('定位成功', result)

          //     // onComplete(result)
          //   } else {
          //     console.log('定位失败', result)

          //     // onError(result)
          //   }
          // })
          geolocation.getCurrentPosition(function (status, result) {
            console.log('定位中...')
            if (status === 'complete') {
              console.log('定位成功')

              // onComplete(result)
            } else {
              console.log('定位失败', result)

              // onError(result)
            }
          })
        })
        // 输入提示
        that.map.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
          var autoOptions = {
            input: 'inputkeyword'
          }
          that.autocomplete = new AMap.Autocomplete(autoOptions)
          var placeSearch = new AMap.PlaceSearch({
            map: that.map
          }) // 构造地点查询类
          AMap.event.addListener(that.autocomplete, 'select', select)// 注册监听，当选中某条记录时会触发
          function select (e) {
            placeSearch.setCity(e.poi.adcode)
            placeSearch.search(e.poi.name) // 关键字查询查询
            // console.log('=-=-=-=select-==-=-=', JSON.stringify(e));
            if (e.poi.location === '') {
              that.geocoder.getLocation(`${e.poi.district}${e.poi.name}${e.poi.address}`, function (status, result) {
                if (status === 'complete' && result.geocodes.length) {
                  var lnglat = result.geocodes[0].location
                  that.sureLnglatMarker(lnglat)
                }
              })
            } else {
              that.sureLnglatMarker(e.poi.location)
            }
          }
        })
        if (this.id === 'addnew') {
          this.queryForm.strategy_personnel_count = ''
          this.queryForm.strategyJgData = []
          // const res = this.requestScopePersonList();
          // this.strategyPersonList = res.Code === 200 && res.Data ? res.Data : [];
          // this.strategyPersonList = res.Data;
          this.initMouseTool()
        } else {
          this.queryForm.strategyJgData = []
          this.queryForm.strategy_personnel_count = ''
          // this.requestScopePersonList();
          // const res = this.requestScopePersonList();
          // this.strategyPersonList = res.Code === 200 && res.Data ? res.Data : [];
          // console.log("res------" + JSON.stringify(res));

          // console.log("strategyPersonList------" + JSON.stringify(this.strategyPersonList));
          this.nowIsEdit()
        }
      })
    },
    sureLnglatMarker (lnglat) {
      var that = this
      var specialPoint = ['北京市', '上海市', '天津市', '重庆市']
      var oneCountry_twoSystems = ['台湾省', '澳门特别行政区', '香港特别行政区']
      that.geocoder.getAddress(lnglat, function (status, result) {
        console.log('=-=-=-=getAddress=', JSON.stringify(result))
        if (status === 'complete' && result.regeocode) {
          const { province, city, district, adcode } = result.regeocode.addressComponent
          that.queryForm.province = province
          that.queryForm.city = city
          that.queryForm.district = district
          that.queryForm.province_code = addressjs.province_list.find(item => item.name === province).code
          //
          that.addressInfo.province = province
          that.addressInfo.city = city
          that.addressInfo.district = district
          that.addressInfo.province_code = addressjs.province_list.find(
            (item) => item.name === province
          ).code
          // 获取市区下拉列
          that.fngetProvinceList({
            specific_level: 2,
            specific_code: that.addressInfo.province_code
          }, 2)
          if (city === '') {
            if (specialPoint.indexOf(province) > -1) { // 直辖市
              that.queryForm.city = province
              that.addressInfo.city = province
            } else if (adcode.startsWith('4690') || adcode.startsWith('6590')) { // 海南省 青海省
              that.queryForm.city = district
              that.addressInfo.city = district
            } else if (oneCountry_twoSystems.indexOf(province) > -1) { // 台湾 香港 澳门
              that.queryForm.province = province
              that.queryForm.city = province
              that.queryForm.district = province
              //
              that.addressInfo.province = province
              that.addressInfo.city = province
              that.addressInfo.district = province
            }
          }
          if (that.queryForm.city === '') {
            // 有部分地区的province === "中华人民共和国"；
            return
          }
          console.log('-=========that.queryForm.city=', that.queryForm.city)
          that.queryForm.city_code = addressjs.city_list.find(item => item.name === that.queryForm.city).code
          that.addressInfo.city_code = addressjs.city_list.find(
            (item) => item.name === that.addressInfo.city
          ).code
          // 获取区级下拉列
          that.fngetProvinceList({
            specific_level: 3,
            specific_code: that.addressInfo.city_code
          }, 3)
          that.addressInfo.district_code = addressjs.district_list.find(
            (item) => item.name === that.addressInfo.district
          ).code
          that.queryForm.district_code = addressjs.district_list.find(item => item.name === that.queryForm.district).code
          // console.log("-=-=-=queryForm-=-", JSON.stringify(that.queryForm));
          var address = result.regeocode.formattedAddress
          that.addressInfo.address = address
          that.addressInfo.longitude = lnglat.lng
          that.addressInfo.latitude = lnglat.lat
        }
      })
    },
    // 适用人员
    handleNodeClick (data, node) {
      this.strategyPersonnelDataId = []
      // console.log(node.checkedNodes);
      var checknode = node.checkedNodes.concat(node.halfCheckedNodes)
      this.person_count = 0
      console.log('适用人员---', data)
      console.log('node', node)
      checknode.forEach(item => {
        if (item.persondata.length > 0) {
          this.person_count += item.persondata.length
          item.persondata.forEach(jtem => {
            this.strategyPersonnelDataId.push(jtem)
          })
        }
      })
      const OrganizationPersonList = []
      const organizationPersonnelID = []
      const checkList = node.checkedNodes
      checkList.forEach(element => {
        if (element.code) {
          OrganizationPersonList.push(element)
        }
      })
      OrganizationPersonList.forEach(item => {
        if (item.children.length === 0 || !item.children) {
          // 到达最底层组织架构
          organizationPersonnelID.push(item.id)
        }
      })
      this.queryForm.selected_organize_ids = organizationPersonnelID // 架构id添加
      this.queryForm.strategy_personnel_count = '已选择' + this.person_count + '人'
      this.queryForm.inspector_personnel = this.strategyPersonnelDataId
    },
    // 递归方法
    getTreeData (data) {
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length > 0) {
          if (data[i].persondata.length > 0) {
            this.person_count += data[i].persondata.length
          }
          // children若为空数组，则将children设为undefined
          this.getTreeData(data[i].children)
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].isshow) {
            this.queryForm.strategyJgData.push(data[i].id)
            if (data[i].persondata.length > 0) {
              this.person_count += data[i].persondata.length
              this.personnel_count += data[i].persondata.length
            }
          }
        }
      }
      return data
    },
    // 获取组织下拉列表
    async fnGetOrganizationSeleteList () {
      const params = {}
      params.select_type = 2 // 1：考勤组 2：围栏策略
      if (this.id !== 'addnew') {
        console.log('have')
        params.ring_fence_id = this.id
      }
      console.log('haveparams', params)
      const res = await getOrganizList(params)
      if (res && res.Code === 200) {
        this.organizationSeleteList = res.Data
        if (this.id === 'addnew') {
          // 新增时默认选中第一项
          this.queryForm.organize_id = this.organizationSeleteList[0]._id
          this.fnGetMembersWithoutConfiguredRules({ organize_id: this.queryForm.organize_id })
        }
      } else {
        this.organizationSeleteList = []
      }
    },
    // 下拉选择组织后获取考勤人员下拉
    selectOrganize () {
      // 重置选择人数
      this.queryForm.strategy_personnel_count = ''
      this.person_count = 0
      const obj = {}
      if (this.queryForm._id) {
        obj.ring_fence_id = this.queryForm._id
      }
      obj.organize_id = this.queryForm.organize_id
      this.fnGetMembersWithoutConfiguredRules(obj)
    },
    // 获取该组织下未配置的考勤人员+收费人员
    async fnGetMembersWithoutConfiguredRules (params) {
      const res = await getFenceWithoutConfiguredRules(params)
      console.log('获取使用人员', res)
      this.strategyPersonList = res.Code === 200 && res.Data ? res.Data : []
      if (this.id === 'addnew') {
        this.person_count = 0
      } else {
        this.getTreeData(this.strategyPersonList) // 回显数据
      }
      this.queryForm.strategy_personnel_count = '已选择' + this.person_count + '人'
      if (this.person_count === 0) {
        this.queryForm.strategy_personnel_count = ''
      }
    },
    // 编辑弹框
    async nowIsEdit () {
      this.personnel_count = 0
      this.queryForm._id = this.id
      const res = await getFenceDetail({ id: this.id })
      const data = res.Data
      this.queryForm = {
        _id: this.id,
        strategy: data.strategy, // 策略名称
        zone_of_action: data.zone_of_action, // 活动区域
        static_rule: data.static_rule, // 静止规则
        control_period: data.control_period, // 管控时段
        regional_location: data.regional_location, // 区域位置
        inspector_personnel: data.inspector_personnel, // 巡检人员id
        organize_id: data.organize_id, // 组织id
        strategy_personnel_count: '',
        // 回显选择id
        strategyJgData: []
      }
      const obj = {
        organize_id: this.queryForm.organize_id,
        ring_fence_id: this.id
      }
      this.fnGetMembersWithoutConfiguredRules(obj)
      // this.requestScopePersonList(true)
      this.queryForm.strategy_personnel_count = '已选择' + this.person_count + '人'
      this.startEditorPoly()
    },
    // 修改
    startEditorPoly () {
      // console.log('修改')
      this.rePaint = false
      if (this.queryForm.regional_location.length === 0) return
      this.adjustment = true
      this.map.clearMap()
      this.map.plugin(['AMap.Polygon', 'AMap.PolyEditor'], () => {
        var path = this.queryForm.regional_location.map((position) => position.split(';'))
        this.polygon = new AMap.Polygon({
          path: path,
          strokeColor: '#FF33FF',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50
        })
        this.map.add(this.polygon)
        // 缩放地图到合适的视野级别
        this.map.setFitView([this.polygon])
        this.polyEditor = new AMap.PolyEditor(this.map, this.polygon)
        const that = this
        this.polyEditor.on('end', function (event) {
          if (!that.rePaint) {
            console.log('polyEditor=-=-=-=', event.target.getPath())
            // console.log('event.target', event)
            that.queryForm.regional_location = event.target
              .getPath()
              .map((position) => `${position.lng};${position.lat}`)
          }
        })
        this.polyEditor.open()
      })
    },
    // 保存
    saveEditorPoly () {
      this.polyEditor && this.polyEditor.close()
      this.adjustment = false
    },
    initMouseTool () {
      const that = this
      // this.requestScopePersonList(false)
      this.mouseTool.polygon({
        strokeColor: '#FF33FF',
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillColor: '#1791fc',
        fillOpacity: 0.4,
        strokeStyle: 'solid'
      })
      this.mouseTool.on('draw', function (event) {
        var oldlnglat = event.obj
          .getPath()
          .map((position) => `${position.lng};${position.lat}`)
        // console.log('oldlnglat-=-==', oldlnglat)
        that.queryForm.regional_location = []
        for (const item of oldlnglat) {
          if (!that.queryForm.regional_location.includes(item)) {
            that.queryForm.regional_location.push(item)
          }
        }
        console.log('queryForm.regional_location-=-==', that.queryForm.regional_location)
        this.close(false)
      })
    },
    // 重新绘制
    resetDrawe () {
      // console.log('重绘')
      if (this.queryForm.regional_location.length > 0) {

      }
      this.rePaint = true
      this.queryForm.regional_location = []
      this.adjustment = false
      this.map.clearMap()
      // this.map.remove(this.polygon);
      this.initMouseTool()
    },
    addTimePeriod (index) {
      this.queryForm.control_period.splice(index + 1, 0, {
        begin_time: '',
        end_time: ''
      })
    },
    reduceTimePeriod (index) {
      this.queryForm.control_period.splice(index, 1)
    },
    async requestScopePersonList (flag) {
      // console.log("测试-----------------");
      const res = await getRingFenceInspectorList(this.queryForm.inspector_personnel)
      this.strategyPersonList = res.Code === 200 && res.Data ? res.Data : []
      // if (flag) {
      //   this.getTreeData(this.strategyPersonList.concat())
      //   this.queryForm.strategy_personnel_count = '已选择' + this.person_count + '人'
      // }
      // console.log("测试-----------------", res);

      //   if (res && res.Code === 200) {
      //     this.strategyPersonList = res.Data;
      //     console.log("this.strategyPersonList = " + JSON.stringify(this.strategyPersonList));
      //   }
    },
    async addStrategy () {
      const res = await addRingFence(this.queryForm)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    async editStrategy () {
      const res = await updateRingFence(this.queryForm)
      if (res && res.Code === 200) {
        this.defaultForm()
      }
    },
    // 省下拉列发生改变触发
    provinceChange (val) {
      console.log('provinceChange', val)
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.province = ''
        this.addressInfo.city = ''
        this.addressInfo.city_code = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      };
      // 清空市区
      this.addressInfo.city = ''
      this.addressInfo.city_code = ''
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.provinceSelectList.find(item => item.specific_code === val)
      this.addressInfo.province = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      // 获取市下拉列表
      this.fngetProvinceList({
        specific_level: 2,
        specific_code: val
      }, 2)
    },
    // 市下拉列发生改变触发
    cityChange (val) {
      if (!val) {
        this.addressInfo.city = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      }
      // 清空区级数据
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.citySelectList.find(item => item.specific_code === val)
      this.addressInfo.city = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      this.autocomplete.setCity(val)
      // 获取区级下拉列表
      this.fngetProvinceList({
        specific_level: 3,
        specific_code: val
      }, 3)
    },
    // 区下拉列发生改变触发
    districtChange (val) {
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.district = ''
        return
      };
      // 筛选出符合条件的数据
      var item = this.districtSelectList.find(item => item.specific_code === val)
      this.addressInfo.district = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
    },
    // 获取省市区列表
    async fngetProvinceList (params, type) {
      // console.log('获取省市区列表');
      // type: 1 省；2 市；3 区
      const res = await getProvinceList(params)
      console.log('fngetProvinceList', res)
      if (type === 1) {
        this.provinceSelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 2) {
        this.citySelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 3) {
        this.districtSelectList = res && res.Code === 200 ? res.Data : []
      }
    },
    // 打开地图对话框
    openMapDialog () {
      this.addressInnerVisible = true
      this.position = this.queryForm.position
      this.fngetProvinceList({
        specific_level: 1
      }, 1)
      if (this.queryForm.province_code) {
        // 获取市下拉列表
        this.fngetProvinceList({
          specific_level: 2,
          specific_code: this.queryForm.province_code
        }, 2)
      }
      if (this.queryForm.city_code) {
        // 获取区下拉列表
        this.fngetProvinceList({
          specific_level: 3,
          specific_code: this.queryForm.city_code
        }, 3)
      }
      this.addressInfo.longitude = this.queryForm.longitude
      this.addressInfo.latitude = this.queryForm.latitude
      this.addressInfo.address = this.queryForm.addres
      this.addressInfo.province = this.queryForm.province
      this.addressInfo.province_code = this.queryForm.province_code
      this.addressInfo.city = this.queryForm.city
      this.addressInfo.city_code = this.queryForm.city_code
      this.addressInfo.district = this.queryForm.district
      this.addressInfo.district_code = this.queryForm.district_code
      this.initMap()
    }
  },
  mounted () {
    console.log(`-=-==-=-=${this.id === 'addnew'}`)
    const that = this
    this.openMapDialog()
    this.fnGetOrganizationSeleteList()
    this.$nextTick(() => {
      // that.initMap()
    })
    // setTimeout(() => {
    //   that.initMap();
    // }, 200);
  }
}
</script>

<style lang="less" scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 400px;

  .float-select {
    position: absolute;
    left: 50%;
    translate: translateX(-50%);
    z-index: 2;
    display: flex;
    flex-direction: row;
  }
  .searchMap {
      display: flex;
      justify-content: start;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 99;
      /deep/.el-input,.el-input--small,.el-input--suffix{
        width: 150px!important;
      }
      /deep/.el-input__inner{
        width: 150px!important;
      }
      .el-select {
        width: 150px !important;
      }
    }
  .float-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: flex;
    flex-direction: row;

    .btn {
      height: 50px;
      width: 50px;
      margin-right: 20px;
      border-radius: 25px;
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
      background: #0077ff;
      color: #ffffff;
    }

  }
  .float-span{
    position: absolute;
    bottom: 10px;
    right: 50%;
    transform: translateX(50%);
    z-index: 2;
    display: flex;
    flex-direction: row;
    .tip{
      height: 40px;
      width: 250px;
      margin-right: 20px;
      border-radius: 25px;
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: rgba(0,0,0,0.5);
      color: #ffffff;
    }
  }
  /deep/.amap-icon{
    box-sizing: border-box;
    border: #0077ff 2px solid;
    border-radius: 50%;
  }
  /deep/.amap-icon img, .amap-marker-content img{
  width: 100% !important;
  height: 100% !important;
  width: 16px;
   height: 16px;
   transform: translateY(-60px);
   border-radius: 50%;
   filter: drop-shadow(#fff 0 60px)  //颜色、x轴偏移量、y轴偏移量,这里的颜色就是你要指定的颜色，不管原来的图片是什么颜色，都会变成这个颜色
}
}

.addform_box {
  max-height: 650px;
  overflow: hidden;
  overflow-y: auto;

  /deep/ .el-input,
  /deep/ .el-select {
    width: 350px;
    // margin-right: 50px;
  }
}
.el-textarea, .el-input{
  // width: 217px!important;
}
.setstyle {
  min-height: 200px;
  padding: 10px 0 !important;
  margin: 0;
  overflow: auto;
  cursor: default !important;

  .setstyle.hover,
  .setstyle:hover {
    background-color: #fff;
  }
}
.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}
</style>
